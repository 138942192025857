import Link from "next/link"

import { ReactComponent as InstagramLogo } from "@spatialsys/assets/icons/material-filled/instagram.svg"
import { ReactComponent as YouTubeLogo } from "@spatialsys/assets/icons/material-filled/youtube.svg"
import { ReactComponent as TwitterXLogo } from "@spatialsys/assets/icons/twitter-x.svg"
import Config from "@spatialsys/web/config"
import { ReactComponent as AppStoreBadge } from "@spatialsys/web/core/img/app-badges/app-store.svg"
import { ReactComponent as MetaQuestBadge } from "@spatialsys/web/core/img/app-badges/meta-quest.svg"
import { ReactComponent as DiscordLogo } from "@spatialsys/web/core/img/icons/socials/discord.svg"
import { Container, cn } from "@spatialsys/web/ui"

interface NavLink {
  label: string
  href: string
  openInNewTab?: boolean
  prefetch?: false
}

const navLinks: NavLink[][] = [
  [
    { label: "Creator Toolkit", href: "https://toolkit.spatial.io", openInNewTab: true, prefetch: false },
    { label: "Support", href: "https://support.spatial.io", openInNewTab: true },
    { label: "About", href: "/about" },
    { label: "Blog", href: "/community", openInNewTab: true, prefetch: false },
    { label: "Brands", href: "/brands", openInNewTab: true },
  ],
  [
    { label: "Pricing", href: "/pricing" },
    { label: "Coins", href: "/coins" },
    { label: "Contact", href: "/contact", openInNewTab: true, prefetch: false },
    { label: "Careers", href: "https://spatial.zohorecruit.com/jobs/Careers", openInNewTab: true, prefetch: false },
    { label: "Privacy", href: "/privacy", openInNewTab: true },
  ],
  [
    { label: "Create Your Gallery", href: "/create-your-gallery", openInNewTab: true },
    { label: "Build an Avatar", href: "/create-an-avatar", openInNewTab: true },
    { label: "Community Rules", href: "/guidelines", openInNewTab: true },
    { label: "Terms", href: "/terms", openInNewTab: true },
  ],
]

type FooterProps = {
  hideBorder?: boolean
}

export function Footer({ hideBorder = false }: FooterProps) {
  return (
    <footer
      className={cn(
        "grid w-full justify-items-center border-t py-4 text-muted-foreground xs:py-6 md:py-8 [&_a:hover]:text-foreground/80 [&_a]:transition-colors",
        // hide the top border on case study pages to avoid double borders
        // one from the page navigation and one from the footer
        hideBorder && "border-none"
      )}
    >
      <Container className="grid auto-rows-auto gap-6 xs:gap-5 md:gap-6">
        <div className="grid w-full grid-flow-col items-center justify-start gap-4">
          <a
            className="flex items-center text-[inherit]"
            href="https://twitter.com/spatial_io"
            aria-label="X formerly known as Twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterXLogo className="h-7 w-7 md:h-8 md:w-8" />
          </a>
          <a
            className="flex items-center text-[inherit]"
            href="https://www.instagram.com/spatial_io"
            aria-label="Instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramLogo className="h-7 w-7 md:h-8 md:w-8" />
          </a>
          <a
            className="flex items-center text-[inherit]"
            href="https://discord.com/invite/spatial"
            aria-label="Discord"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* the docs say they have an inheritViewBox prop so we don't have to hardcode the viewBox but I think the docs might be incorrect */}
            <DiscordLogo viewBox="-7 -7 86 70" className="h-7 w-7 md:h-8 md:w-8" />
          </a>
          <a
            className="flex items-center text-[inherit]"
            href="https://www.youtube.com/c/Spatial_io"
            aria-label="YouTube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTubeLogo className="h-7 w-7 md:h-8 md:w-8" />
          </a>
        </div>

        <div className="grid justify-items-stretch gap-x-3 gap-y-6 xs:grid-cols-[auto_1fr]">
          <nav className="grid grid-flow-col items-start justify-start gap-5 text-left xs:gap-7">
            {navLinks.map((col, index) => (
              <div key={index} className="grid w-fit max-w-[132px] gap-2 xs:max-w-[160px]">
                {col.map((item) => (
                  <Link
                    key={item.label}
                    href={item.href}
                    className="w-fit text-xs text-[inherit] no-underline md:text-sm"
                    target={item.openInNewTab ? "_blank" : undefined}
                    rel={item.openInNewTab ? "noreferrer" : undefined}
                    prefetch={item.prefetch}
                  >
                    {item.label}
                  </Link>
                ))}
              </div>
            ))}
          </nav>

          <div className="grid justify-start gap-2 xs:content-end xs:items-end xs:justify-end xs:gap-2">
            <span className="text-xs sm:text-end md:text-sm">
              © {new Date().getFullYear()} Spatial Systems, Inc. All Rights Reserved.
            </span>

            <div className="grid grid-flow-col items-center justify-evenly gap-4 xs:justify-items-end">
              <a href="https://apps.apple.com/us/app/spatial/id1528403747" target="_blank" rel="noreferrer">
                <AppStoreBadge className="h-auto w-[25vw] max-w-[120px] xs:w-[100px]" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=io.spatial.spatial"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src={`${Config.PUBLIC_ASSETS_BASE_URL}/homepage/images/google-play.png`}
                  alt="Get it on the Play Store"
                  className="h-auto w-[25vw] max-w-[120px] xs:w-[100px]"
                />
              </a>
              <a href="https://oculus.com/experiences/quest/2927141310670477" target="_blank" rel="noreferrer">
                <MetaQuestBadge className="h-auto w-[25vw] max-w-[120px] fill-foreground xs:w-[88px] sm:w-[120px]" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}
